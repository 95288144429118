@import '@design';

::v-deep .modal-dialog {
  word-break: break-word;
  word-wrap: break-word;

  .close {
    margin: 0;
    padding: 0;
  }
}

.download-buttons .btn {
  width: 100%;
  max-width: 320px;
  padding-left: $padding-tiny;
  padding-right: $padding-tiny;
  text-align: left;
}

.btn-orange {
  background: $orange;
  color: white;
  border: 1px solid $orange;
}

.line {
  border-left: 1px solid $gray-300;
  height: 100px;
  text-align: center;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
}

img.app-icon {
  max-width: 50px;
}

@media only screen and (max-width: 540px) {
  .divider {
    display: none;
  }

  ::v-deep .modal-dialog {
    max-width: 600px;
  }
}

@media only screen and (min-width: 540px) {
  ::v-deep .modal-dialog {
    max-width: 700px;
  }
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.25em;
  line-height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);

  &.flag-icon-squared {
    width: 1em;
  }
}




































































































































































































































































































































































































































































.modal-header {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: start;
  border: 0;
  padding-block-end: 20px;
  font-size: 1rem;
  text-align: start;
  @media (min-width: 1024px) {
    flex-direction: row;
  }

  .refresher-title {
    font-size: 1.275rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .info {
    max-width: 70%;
  }
}
.modal-body {
  padding: 0;
  font-size: 1.2rem;
  text-align: start;
  &.lastStep {
    background-image: url('~@/src/assets/images/training/confetti.png');
  }

  .radio-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 40px;
    margin-block: 4px;
    &.highlight {
      border: 1px solid #dc3545;
      border-radius: 5px;
      &.correct {
        border: 1px solid #28a745;
      }
      label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }
    input:focus {
      outline: none;
    }
  }

  .option-focused {
    outline: var(--brand-color-primary-half-opacity) solid 0.2em;
  }

  .form-check-input:focus {
    outline: none;
  }

  .feedback {
    margin-block: 20px 0;
  }

  .last-step {
    .content {
      padding: 10px;
      @media (min-width: 1024px) {
        padding: 150px;
      }
    }
    text-align: end;
  }
}
.modal-footer {
  border: 0;
  padding: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    display: block;
    width: 100%;
    @media (min-width: 1024px) {
      display: inline-block;
      width: auto;
    }
  }
}
.skeleton-loader {
  .step {
    p.question {
      .b-skeleton-text {
        height: 6vh;
      }
    }
    .options {
      .b-skeleton {
        height: 3vh;
      }
    }
  }
}

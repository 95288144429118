@import '@design';

.leaderboard-container {
  width: 160px;
}

.badge-primary {
  font-size: $font-size-small;
  padding: 6px 10px;
  right: 50px;
  top: 20px;
  border: 2px solid white;
}

.more-info {
  font-size: $font-size-small;
}

@import '@design';

.heading {
  text-align: start;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0.5rem;
}

.avatar {
  font-size: $font-size-small;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;

  svg {
    position: relative;
    top: calc(50% - 8px);
  }
}

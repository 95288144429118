@import '@design';

.rtl {
  .team-table {
    .show-all {
      left: 25px;
      right: auto;
    }
  }
}

.team-table {
  ::v-deep .b-table-details {
    background-color: $gray-100;
  }

  .progress-percentage {
    min-width: 40px;
  }

  .team-members {
    color: $gray-500;
    cursor: default;
  }

  .filters {
    margin: 1rem 0;

    > div {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 50%;
      }
      @media screen and (min-width: 1024px) {
        width: 20%;
      }
      display: flex;
      padding-inline-end: 0.5rem;
      padding-block-end: 0.5rem;
    }

    .filter-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray-200;
      min-height: 84px;
      border-radius: 5px;
      padding: 2.5px;
      text-align: center;
      text-wrap: wrap;
    }
  }

  .show-all {
    top: 0%;
    right: 25px;
    left: auto;
    margin-top: 1.5rem;
    position: absolute;
  }

  .status-indicator {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }

  ::v-deep .progress {
    width: 100px;
    background-color: $gray-200;
    border-radius: 0.25em;
  }

  ::v-deep .progress-bar {
    border-radius: 0.25em;
    height: 8px;
  }

  .progress-text {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: $padding-small;
  }

  .filter-block:hover {
    cursor: pointer;
  }

  .table-contents-container {
    min-height: 20vh;
    display: flex;
    justify-content: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: start;
  }

  ::v-deep table {
    th,
    td {
      border: 0px;
      border-bottom: 1px solid #e5e5e5;

      &:hover {
        cursor: pointer;
      }
    }
  }

  ::v-deep div#loader-wrap {
    margin: 25vh auto;
  }
}

$orange: #f26721;
$primary: $orange;
$secondary: $gray-200;
$body-color: $gray-800;
$body-bg: $gray-100;
$yiq-text-dark: $gray-700; // Btn color on light background, eg. btn-secondary
$text-muted: $gray-500;
$font-size-base: 0.85rem;
$font-size-small: 0.7rem;
$card-border-width: 0;
$card-cap-bg: #fff;
$card-spacer-x: 1.2em;
$card-spacer-y: 1.2em;
$progress-bg: $gray-100;
$border-color: $gray-200;
$input-btn-padding-y: 0.7rem;
$input-btn-padding-x: 0.9rem;
$btn-padding-x: 2em;
// $btn-padding-y: 0.5rem;
$font-family-base: 'Open Sans', sans-serif;
$box-shadow: 0.15rem 0.15rem 0.25rem rgba(#000, 0.1);
$form-group-margin-bottom: 1.4rem;
$modal-md: 420px;
$modal-inner-padding: 2.5rem;
$modal-content-border-color: none;
$modal-dialog-margin-y-sm-up: 6rem;
$enable-print-styles: false;
$headings-font-weight: 600;
$tooltip-bg: $gray-800;
$tooltip-color: $gray-100;
$tooltip-padding-y: 1rem;
$tooltip-padding-x: 1rem;
$tooltip-max-width: 400px;
$tooltip-margin: 10px;
$tooltip-opacity: 1;
$btn-focus-box-shadow: 0 0 0 0.2em rgba($primary, 0.5);

$btn-font-size-sm: 0.85rem;

// Variables
$padding-tiny: 12px;
$padding-small: 16px;
$padding-medium: 20px;
$padding-large: 24px;
$padding-huge: 30px;
$padding-default: 1rem;
$margin-tiny: $padding-tiny;
$margin-small: $padding-small;
$margin-medium: $padding-medium;
$margin-large: $padding-large;
$margin-huge: $padding-huge;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;

$card-box-shadow: 0 2px 5px $color-light-gray;
$width-full: 100%;
$width-half: 47%;
$border-radius-1: 2px;
$border-radius-2: 5px;
$border-radius-3: 10px;
$container-background: $color-lighter-gray;

// Spacers
$spacer: 1rem !default;
$spacer-1: ($spacer * 0.25);
$spacer-2: ($spacer * 0.5);
$spacer-3: ($spacer);
$spacer-4: ($spacer * 1.5);
$spacer-5: ($spacer * 3);

// Links

$link-hover-decoration: none;

// Nav Dropdown

$dropdown-padding-y: 0;
$dropdown-padding-x: 0;
$dropdown-item-padding-y: 0.8rem;
$dropdown-border-color: $gray-200;
$dropdown-link-color: $gray-500;

$dropdown-link-hover-color: $gray-700;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $gray-700;
$dropdown-link-active-bg: $gray-100;

// Nav link
$navbar-light-color: $gray-500;
$navbar-light-hover-color: $gray-700;
$navbar-light-active-color: $gray-700;

// Nav Toggler styling

$navbar-light-toggler-border-color: transparent;

// Progress Bar

$progress-height: 0.8rem;
$progress-border-radius: 10px;

// Forms

$input-color: $gray-700;
$input-border-color: $gray-200;
$input-focus-border-color: $gray-400;
$input-btn-focus-box-shadow: 0 0 0 0.2em $gray-100;

// Badges
$badge-border-radius: 2em;

// Language Select modal
$language-modal-max-width: 500px;
$language-modal-max-width-small: 350px;
$language-modal-padding: 12px;

// Language Select Dropdown
$language-select-max-width: 500px;
$language-select-max-width-small: 300px;
$language-select-max-height-small: 350px;
$language-select-padding: 12px;

// Multiselect

$multi-select-max-height: 240px;
$multi-select-min-width: 280px;
$multi-select-max-width: 500px;

// Badges
$badge-padding-large: 6rem;
$badge-padding-default: 1rem;
$badge-padding-medium: 0.75rem;
$badge-padding-small: 0.5rem;
$badge-padding-tiny: 0.25rem;

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

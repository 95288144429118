@import '@design';

.download-certificate-link {
  border-right: 1px solid $gray-300;
  height: 20px;
  padding-right: $padding-small;
}

.enrollment-title {
  font-weight: 700;
  margin-bottom: 0;
  width: 100%;
}

.description {
  margin: 0.25rem 0 0 0;
  width: 100%;
  color: $text-muted;
}

.enrollment-details {
  color: $gray-600;
  background: $gray-100;
  padding: 0.5rem 0.75rem;
  margin: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;

  span,
  svg {
    margin: 0 0.25rem;
  }

  &.highlight {
    color: white;
    background-color: $gray-700;
  }
}

$primary-preview-image-size: 350px;
$secondary-preview-image-size: 290px;

.assessment,
.policy,
.course {
  &.card {
    @include media-breakpoint-down(md) {
      max-width: $primary-preview-image-size;
    }

    .img-preview {
      width: $primary-preview-image-size;
      height: $primary-preview-image-size;
      background-image: none !important;

      @include media-breakpoint-up(lg) {
        width: $secondary-preview-image-size;
        height: $secondary-preview-image-size;
      }

      &.primary {
        width: $primary-preview-image-size;
        height: $primary-preview-image-size;
      }

      img,
      svg {
        width: 100%;
        height: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .complete-label {
    position: absolute;
    top: $padding-large;
    padding: $padding-tiny $padding-large;
    color: $color-white;
    background-color: $primary;
    border-radius: 0 $border-radius $border-radius 0;
    font-size: 1.125em;
    font-weight: 600;
  }

  .layover {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-black;
    opacity: 0.3;
  }
}

div.hide-if-empty:empty {
  display: none;
}

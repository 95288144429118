@import '@design';

.training-statistics {
  .progress-container {
    padding: 0 1rem;
    margin-bottom: 1rem;

    .progress-labels {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      label {
        color: #757578 !important;
      }
    }
  }

  .training-status {
    bottom: 0;
    width: 100;
    padding: 1rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;

        div,
        div p,
        button {
          width: 100%;
          text-align: center;
        }

        div p {
          padding: 0 0 1rem;
        }
      }

      .training-checklist {
        display: flex;
        flex-direction: row;
        align-items: center;

        .progress-text {
          margin: 0 0.5rem;
        }

        .svg-container {
          width: 35px;
          max-height: 40px;
          height: 100%;

          @include media-breakpoint-down(lg) {
            display: none;
          }

          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .progress {
    background-color: $gray-200;
    border-radius: 0.25em;
  }

  ::v-deep .progress-bar {
    border-radius: 0.25em;
    height: 30px;
  }
}

@import '@design';

.survey-modal {
  color: #555;
  font-size: 16px;
  text-align: left;

  h4 {
    color: #333;
  }

  .modal-body {
    padding: 0 1em 0;
  }

  .skip-survey-button {
    margin-bottom: 1rem !important;
    font-size: $font-size-base;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      min-width: 600px;
    }
  }

  hr.dashed {
    border-style: dashed;
    border-top-width: 2px;
  }

  .rating-rows {
    padding: 0.5em 0;

    .col {
      line-height: 2.2;
    }

    a {
      color: #555;
      cursor: default;
    }
  }

  button[disabled] {
    cursor: not-allowed;
  }
}

::v-deep .vue-treeselect {
  max-width: 100%;
}

.team-select-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .actions {
    margin-top: 15px;
  }
}

::v-deep .vue-treeselect__input-container {
  outline: none !important;
}

@import '@design';

@-webkit-keyframes animatePlane {
  to {
    transform: translatey(10px);
  }
}

.plane-wrapper {
  z-index: 1;
}

.plane {
  width: 200px;
}

.plane-animated {
  animation: animatePlane 2.5s alternate infinite ease-in-out;
}

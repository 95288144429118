@import '@design';

.status-indicator {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

::v-deep table {
  text-align: start;

  th,
  td {
    border: 0px;
    border-bottom: 1px solid #e5e5e5;

    &:hover {
      cursor: pointer;
    }
  }
}

.team-dashboard-container {
  position: relative;
  min-height: 70vh;

  .manager-alert {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
  }
}

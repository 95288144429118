@import '@design';

#language-selection-modal {
  .modal-footer {
    padding: $padding-small;
  }

  .modal-dialog {
    max-width: 750px;

    .modal-body {
      padding: 10px;
    }

    .languageBtnContainer {
      padding: 0 10px;
      .btn-secondary {
        line-height: 24px;
        font-weight: 500;
        padding: 10px 5px;
      }
    }
  }
}

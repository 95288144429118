@import '@design';

::v-deep .small-envelope {
  svg {
    max-width: 135px;
  }
}

.badge-container {
  max-width: 135px;

  .badge-primary {
    font-size: $font-size-small;
    padding: 6px 10px;
    right: 1.5rem;
    top: 0.5rem;
    border: 2px solid white;
  }
}

@import '@design';

$timeline-circle-size: 2.2em;
$timeline-circle-top: 42%;
$timeline-width: 3.2em;

.timeline {
  position: relative;
  width: $timeline-width;
  border-left: 1px solid $gray-300;

  .circle {
    position: absolute;
    top: $timeline-circle-top;
    width: $timeline-circle-size;
    height: $timeline-circle-size;
    margin: 0 (-$timeline-circle-size / 2);
    background: $body-bg;
  }
}

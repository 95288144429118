




























































.impersonating {
  background-color: #f26721;
  color: white;
  font-size: 18px;
  .row {
    padding: 10px 0;
  }
  .right {
    text-align: right;
  }
  a {
    font-weight: bold;
    color: white;
  }
  .notifications {
    display: inline-block;
    ::v-deep li {
      list-style-type: none;
    }
    ::v-deep .fa-2x {
      font-size: 1.3em;
    }
    ::v-deep .nav-link {
      padding: 0 0.5rem;
    }
  }
}

.bar-link-like {
  font-weight: bold;
  color: white;
  &:hover {
    cursor: pointer;
  }
}

@media print {
  .impersonating {
    display: none !important;
  }
}

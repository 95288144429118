@import '@design';

.policy {
  min-height: 85px;
  margin-top: -1px;
  border-top: 1px dashed $border-color;
  transition: min-height 1.5s;

  &.action {
    min-height: 100px;
  }

  .actions {
    width: 20em;

    .btn {
      max-width: 100%;
      white-space: normal;
    }
  }
}

.loading-text {
  color: rgba(255, 255, 255, 0.3);
}

@include media-breakpoint-down(md) {
  .btn {
    margin-top: $padding-small;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}



















.all-krs-completed {
  width: 50%;
  text-align: center;
  margin: 50px auto;
}

.modal-dialog.modal-md {
  svg {
    max-width: 220px;
  }

  .desktop {
    display: inline-block;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobile {
    display: inline-block;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}






















































































































































@import '@design';

@include media-breakpoint-down(md) {
  .card-spacing {
    margin-left: 15px;
  }
}

@import '@design';

.phishing-test-results {
  .phising-results {
    text-align: start;
    padding: 0 1.5rem 1.5rem 1.5rem;

    .info {
      padding-left: $padding-tiny / 2;
      color: $gray-400;
    }

    .progress {
      background-color: $gray-200;
      border-radius: 0.25em;
    }

    ::v-deep .progress-bar {
      border-radius: 0.25em;
      height: 30px;
    }

    .progress-label {
      max-width: 300px;
    }
  }

  .phishing-envelope {
    width: 120px;

    span {
      ::v-deep svg {
        max-height: 100px;
      }
    }
  }
}

@import '@design';

@include media-breakpoint-down(xs) {
  .navbar-nav {
    width: 100%;
    text-align: center;
  }
}

.nav-item {
  a {
    color: $gray-800 !important;
  }
}

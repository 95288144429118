



















































#risk-score-v2-opt-in-modal .modal-footer {
  padding: 0.25rem !important;
}

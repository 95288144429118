

















































































@import '@design';

.card-footer {
  background: none;
  border-top: 1px dashed $gray-200;
}

.card.badge-not-earned {
  background-color: $color-white--semi-transparent;
  h5 {
    color: $gray-700;
  }
  p {
    color: $gray-500;
  }
}

.badge {
  width: 100px;
  height: 100px;
}

@import '@design';

.languageBtnContainer {
  margin: 2px 0; // to accomodate focus outline
  padding: 0px;

  button {
    font-size: 12px;
    width: 210px;
    padding: $padding-tiny / 2 $padding-tiny;
    background-color: $white;
    border: none;
    border-radius: $border-radius-2;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.active {
      background-color: $gray-100 !important;
    }

    &:hover {
      background-color: $gray-100 !important;
    }
  }

  .modal-dialog {
    max-width: $language-modal-max-width;

    .modal-body {
      padding: $language-modal-padding;
    }
  }
}

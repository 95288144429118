@import '@design';

.custom-checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .custom-checkbox {
    position: relative;
    cursor: pointer;
    min-height: 20px;
    min-width: 20px;
    border: 1px solid $gray-300;
    border-radius: 5px;
    background-color: $white;

    &[aria-disabled='true'] {
      background-color: $gray-300;
      cursor: default;
    }

    &[aria-checked='true'] {
      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 6px;
        width: 7px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }

    &[aria-checked='true'][aria-disabled='true'] {
      opacity: 0.5;
    }
  }

  .custom-checkbox-label {
    font-size: 14px;
    margin: 0;
  }
}
















































































// Design variables and utilities from src/design.
@import '@design';
@import 'bootstrap/scss/bootstrap-reboot.scss';
@import 'bootstrap/scss/_grid.scss';
@import 'bootstrap/scss/_type.scss';
@import 'bootstrap/scss/_alert.scss';
@import 'bootstrap/scss/_modal.scss';
@import 'bootstrap/scss/_close.scss';
@import 'bootstrap/scss/_transitions.scss';
@import 'bootstrap/scss/_button-group.scss';
@import 'bootstrap/scss/_buttons.scss';
@import 'bootstrap/scss/_forms.scss';
@import 'bootstrap/scss/_custom-forms.scss';
@import 'bootstrap/scss/_input-group.scss';
@import 'bootstrap/scss/_card.scss';
@import 'bootstrap/scss/_dropdown.scss';
@import 'bootstrap/scss/_list-group.scss';
@import 'bootstrap/scss/_forms.scss';
@import 'bootstrap/scss/_tables.scss';
@import 'bootstrap/scss/_navbar.scss';
@import 'bootstrap/scss/_badge.scss';
@import 'bootstrap/scss/_carousel.scss';
@import 'bootstrap/scss/_spinners.scss';
@import 'bootstrap/scss/_tooltip.scss';
@import 'bootstrap/scss/_utilities.scss';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vue-snotify/styles/simple';
@import '~animate.css/animate.css';
@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';

// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';
@import '~intro.js/introjs.css';

#app {
  height: 100vh;
  background: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Right-to-Left Support
#app.rtl {
  direction: rtl;
}
#app.rtl div.dropdown-menu,
#app.rtl table.table .name,
#app.rtl .enrollment-div,
#app.rtl ~ .introjs-tooltipReferenceLayer .introjs-tooltip,
#app.rtl ~ .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-bullets {
  text-align: right;
}
#app.rtl .btn.white.text-left.btn-secondary {
  text-align: right !important;
  margin-right: 0;
}
#app.rtl .modal-header .close {
  margin-left: -1rem;
}

#app.rtl table.table .you-marker-wrap {
  direction: ltr;
}
#app.rtl table.table #Popcorn-Theatre {
  transform: scaleX(-1);
  transform-origin: center;
}
#app.rtl table.table #Your-Group {
  transform: scaleX(-1) translateX(85px);
  transform-origin: center;
  direction: rtl;
}

#app.rtl .start-course-button,
#app.rtl .enrollment-wrapper {
  float: right !important;
}

#app.rtl a.tour-start {
  left: 24px;
  right: unset;
}

#app.rtl ~ div.tooltip.bs-tooltip-top div.tooltip-inner {
  direction: rtl;
  text-align: right;
}

#app.rtl
  ~ .introjs-tooltipReferenceLayer
  .introjs-tooltip
  .introjs-bullets
  li[role='presentation'],
#app.rtl ~ .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-skipbutton {
  float: right;
}

#app.rtl ~ .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-nextbutton,
#app.rtl ~ .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-donebutton {
  float: left;
}

#nprogress {
  .bar {
    background: $primary;
  }
  .peg {
    box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// Global float label styles
.vfl-has-label .vfl-label {
  color: $gray-600 !important;
}
.vfl-label.vfl-label-on-input {
  top: -1.7em;
  &.vfl-label-on-focus {
    top: -1.7em;
  }
}

// Global validation error styles
.invalid-feedback {
  position: absolute;
  right: 1em;
  left: auto;
  text-align: right;
}

// Global responsive buttons
.btn-block-sm {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;

    + .btn-block-sm {
      margin-top: $btn-block-spacing-y;
    }
  }
}

.btn-block-md {
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;

    + .btn-block-md {
      margin-top: $btn-block-spacing-y;
    }
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5) !important;
}

@import '@design';

.ppp-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: start;
}





















































#badges-banner {
  max-height: 160px;
  display: flex;
  border: var(--brand-color-primary-lighten) 1px solid;
  @media (max-width: 1224px) {
    max-height: 380px;
  }
  .card-body {
    padding: 0;
  }
}

#trophy-image {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    text-align: center;
    > div {
      p {
        padding-top: 1.5vh;
      }
    }
  }

  &.rtl {
    background-position: left;
  }
}

#test-knowledge {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    text-align: center;
    > div {
      p {
        padding-top: 1.5vh;
      }
    }
  }

  &.rtl {
    background-position: left;
  }
}

@import '@design';

.krs-container {
  width: 120px;
}

.badge-primary {
  font-size: $font-size-small;
  padding: 6px 10px;
  right: 25px;
  top: 0px;
  border: 2px solid white;
}



















.no-assigned-krs {
  width: 50%;
  text-align: center;
  margin: 200px auto;
}

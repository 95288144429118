@import '@design';

::v-deep .card-body {
  padding: 0;
}

.col-sm-4,
.col-sm-6,
.col-sm-8,
.col-sm-12,
.col-md-4,
.col-md-6,
.col-md-8,
.col-md-12 {
  padding: 10px 10px;
}

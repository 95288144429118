





















































































































@import '@design';
@import 'bootstrap/scss/utilities/_shadows.scss';

.rtl {
  .btn-close {
    @media only screen and (min-width: 1024px) {
      left: 15px;
    }
  }
}

.learner-app-banner {
  .card {
    position: relative;
    margin-bottom: 1.5rem;
    @extend .shadow-sm;
    .card-body {
      display: flex;
      flex-direction: column;
      .btn-close {
        cursor: pointer;
        color: #757578;
        font-weight: 700;
        text-align: end;
        width: 100%;
        padding: 0;
        @media only screen and (min-width: 1024px) {
          position: absolute;
          top: 10px;
          right: 15px;
          width: auto;
        }
      }
      .notification {
        @media only screen and (min-width: 1024px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .content {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          text-align: start;
          img {
            max-width: 50px;
          }
          .copy {
            padding: 0 1rem;
            p {
              margin: 0;
            }
          }
        }
        .actions {
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          button {
            margin: 1rem 0.5rem 0 0.5rem;
          }
          .btn-orange {
            background: $orange;
            color: white;
            border: 1px solid $orange;
          }
          @media only screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: end;
          }
          @media only screen and (min-width: 1024px) {
            justify-content: center;
            button {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

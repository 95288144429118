@import '@design';

.help {
  right: $spacer;
  left: auto;
  bottom: $spacer;
  z-index: 100;
  border-radius: 2em;

  svg {
    margin: 0 0.5rem;
  }
}

.rtl {
  .help {
    right: auto;
    left: $spacer;
  }
}

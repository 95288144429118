@import '@design';

$tile-image-size: 255px;

.rtl {
  .store-item {
    .card > .card-body,
    .store-item-title,
    .enrollment-type {
      text-align: right;
    }
  }
}

.store-item {
  max-width: $tile-image-size;

  .card {
    width: 100%;
    border: 0px;

    .card-body {
      text-align: left;
      padding: 0;

      .image-section {
        max-height: $tile-image-size;
      }

      .overlay {
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease;
        background-color: $black;
      }

      .overlay.complete {
        opacity: 0.5;
        background-color: $white;
      }

      .image-container {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }

      .store-item-title {
        color: $gray-900;
        min-height: 40px; // make space for 2 lined titles
      }

      .orange-text {
        color: $orange;
        font-size: $font-size-base;
      }

      .card-body-container {
        position: relative;
        background-color: white;
        width: 100%;
        height: 100%;
        height: 90px;

        .enrollment-list {
          opacity: 0;
        }

        .card-body-container-content {
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;

          transform: translateY(0%);
          -webkit-transform: translateY(0%);
          -o-transform: translateY(0%);
          -ms-transform: translateY(0%);

          position: absolute;
          background-color: white;
        }
      }

      &:hover {
        .overlay {
          opacity: 0.5;
        }

        .overlay.complete {
          opacity: 0.25;
        }

        .card-body-container {
          .card-body-container-content {
            .enrollment-list {
              opacity: 1;
            }

            bottom: 0;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;

            transform: translateY(-15px);
            -webkit-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
          }
        }
      }
    }
  }
}

// The svg styling below was added, because the svg did not show on IE.

::v-deep .svg {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;

  &.complete {
    opacity: 0.5;
  }
}

::v-deep svg.branded {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

@import '@design';

:export {
  color: $color-gray;
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  top: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin: 12px auto;

  div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid;
    border-color: $color-gray transparent transparent;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.loaderLarge {
  position: relative;
  display: block;
  width: 4em;
  height: 4em;
  margin: 45vh auto;

  div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid;
    border-color: $color-gray transparent transparent;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

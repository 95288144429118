





























































































@import '@design';

.btn-orange {
  background: $orange;
  color: white;
  border: 1px solid $orange;
}

::v-deep .modal-header {
  border-bottom: 0px;
  padding-bottom: 0px;
}

::v-deep .modal-body {
  padding-top: 0px;
}

.line {
  border-left: 1px solid $gray-300;
  height: 320px;
  text-align: center;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
}

@import '@design';

$avatar-size: 15px;

.navbar {
  background: $color-white;

  // This causes styles to apply to all items within this component
  :global {
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: inherit;
    }

    .dropdown-item:hover {
      outline: none;
    }

    .navbar-toggler:focus {
      outline: none;
    }

    .navbar-toggler:hover {
      color: $gray-700;
    }

    .dropdown-item svg {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }

    .navbar-nav {
      .nav-link.active {
        color: $color-black !important;
        padding-bottom: 18px;
        border-bottom: 3px solid;
      }
    }

    .badge-primary {
      padding: 4px 6px;
    }

    a.dropdown-item {
      padding: 0.8rem 0.6rem;
    }

    .b-nav-dropdown {
      z-index: 3 !important;
    }

    .dropdown-item {
      &:hover .custom-icon svg path,
      &:focus .custom-icon svg path {
        fill: $gray-800;
      }

      .custom-icon {
        svg path {
          fill: $gray-500;
        }

        svg {
          max-width: 14px;
          max-height: 14px;
          margin-bottom: 4px;
        }
      }
    }

    .dropdown-divider {
      margin: 0;
    }

    @include media-breakpoint-down(md) {
      .nav-item {
        margin: $padding-tiny 0;
      }

      .b-nav-dropdown {
        margin: $padding-tiny 0px;
      }
    }

    @include media-breakpoint-down(md) {
      .b-nav-dropdown {
        padding: 0px;
        margin: $padding-tiny 0px !important;
      }
    }
  }
}

.skipToMain {
  position: absolute;
  top: -99px;

  &:focus {
    top: inherit;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.avatar {
  display: inline-block;
  height: $avatar-size;
  width: $avatar-size;
  fill: $gray-500;

  svg {
    position: relative; // Added
    margin-bottom: 4px; // for IE11
  }
}

@include media-breakpoint-down(sm) {
  .avatar {
    display: none;
  }
}

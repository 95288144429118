@import '@design';

.store-item-badge {
  position: absolute;
  color: $white;
  top: 2 * $badge-padding-medium;
  padding: $badge-padding-medium $badge-padding-default;
  border-radius: 0 $border-radius $border-radius 0;
  font-size: 1.125em;
  font-weight: 600;

  p {
    font-weight: bold;
    margin-bottom: 0px;
  }
}

.greyed {
  background-color: $gray-700;
}






































































































@import '@design';

.btn-orange {
  background: $orange;
  color: white;
  border: 1px solid $orange;
}

.qr-loading {
  opacity: 0.2;
}

::v-deep .banner {
  min-height: 100px;
  max-height: 300px;
  background-color: #2b2b2b;
  color: #fff;
  overflow: hidden !important;
}

::v-deep .modal-header {
  background-color: #2b2b2b;
  border-bottom: 0px;
  padding-bottom: 0px;
  .close {
    color: #fff;
  }
}

::v-deep .mobile-image {
  position: absolute;
  height: 450px;
  right: 90px;
}

::v-deep .modal-body {
  padding: 0px;
}

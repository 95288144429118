@import '@design';

.badge-container {
  width: 160px;
}

.badge-primary {
  font-size: $font-size-small;
  padding: 6px 10px;
  right: 40px;
  top: 8px;
  border: 2px solid white;
}

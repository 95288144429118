@import '@design';

.training-progress-container {
  padding: 0 1.5rem 1.5rem 1.5rem;
  width: 100%;
  text-align: start;

  .progress {
    background-color: $gray-200;
    border-radius: 0.25em;
  }

  ::v-deep .progress-bar {
    border-radius: 0.25em;
    height: 30px;
  }

  .percentage-label {
    font-size: 2.2rem;
    font-weight: bold;
  }

  .progress-label {
    max-width: 400px;
  }
}

@-webkit-keyframes animateCloud {
  0% {
    margin-left: 1090px; // Max width of the tile
  }

  100% {
    margin-left: -200px;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: 1090px; // Max width of the tile
  }

  100% {
    margin-left: -200px;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: 1090px; // Max width of the tile
  }

  100% {
    margin-left: -200px;
  }
}

/* ANIMATIONS */

.x1 {
  &.animated {
    -webkit-animation: animateCloud 35s linear infinite;
    -moz-animation: animateCloud 35s linear infinite;
    animation: animateCloud 35s linear infinite;
  }

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x2 {
  &.animated {
    -webkit-animation: animateCloud 20s linear infinite;
    -moz-animation: animateCloud 20s linear infinite;
    animation: animateCloud 20s linear infinite;
  }

  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  transform: scale(0.25);
}

.x3 {
  &.animated {
    -webkit-animation: animateCloud 30s linear infinite;
    -moz-animation: animateCloud 30s linear infinite;
    animation: animateCloud 30s linear infinite;
  }

  -webkit-transform: scale(0.27);
  -moz-transform: scale(0.27);
  transform: scale(0.27);
}

.x4 {
  &.animated {
    -webkit-animation: animateCloud 18s linear infinite;
    -moz-animation: animateCloud 18s linear infinite;
    animation: animateCloud 18s linear infinite;
  }

  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  transform: scale(0.35);
}

.x5 {
  &.animated {
    -webkit-animation: animateCloud 25s linear infinite;
    -moz-animation: animateCloud 25s linear infinite;
    animation: animateCloud 25s linear infinite;
  }

  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  transform: scale(0.1);
}

/* OBJECTS */
.cloud {
  background: #fff;

  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;

  height: 60px;
  position: relative;
  width: 175px;
}

.cloud:after,
.cloud:before {
  background: #fff;
  content: '';
  position: absolute;
  z-index: -1;
}

.cloud:after {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;

  height: 50px;
  left: 25px;
  top: -25px;
  width: 50px;
}

.cloud:before {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;

  width: 90px;
  height: 90px;
  right: 25px;
  top: -45px;
}

$snotify-success: $gray-500 !default;
$snotify-info: $gray-500 !default;
$snotify-warning: $gray-500 !default;
$snotify-error: $gray-500 !default;

.snotify-rightTop,
.snotify-leftTop,
.alert-container {
  top: $spacer * 2;
}

.snotifyToast {
  border-radius: 4px;
  box-shadow: $box-shadow;
  &__inner {
    min-height: 70px;
    font-size: $font-size-base;
  }

  .snotify-icon {
    position: absolute;
    right: $padding-small;
    width: 100%;
    max-width: 24px;
    height: 100%;
    max-height: 24px;
  }

  &.alert-container {
    position: absolute;
    right: $spacer;
    left: auto;
    background-color: white;
    z-index: 100;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    height: auto;
    .snotifyToast__inner {
      align-items: inherit; // IE11 text overflow fix
      font-size: 14px;
    }
    .content {
      max-width: 250px;
      padding: 0 1rem;
    }
    button {
      font-size: $font-size-base;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      svg {
        margin: 0.25rem 0.5rem;
      }
    }
  }
}

.rtl {
  .snotifyToast {
    &.snotify-info {
      border-left: 4px solid transparent !important;
      border-right: 4px solid #0083c2 !important;
    }
    &.alert-container {
      left: $spacer;
      right: auto;
    }
  }
}

@import '@design';

.top-arrow {
  position: absolute;
  z-index: $z-index-1;
  display: none;
  padding: $padding-tiny / 2;
  border: solid $color-light-gray;
  border-width: 0 1px 1px 0;
  transform: rotate(-135deg);

  @include media-breakpoint-up(md) {
    top: 2px;
    left: -7px;
    display: block;
  }
}

@import '@design';

.risk-container {
  max-width: 300px;
  margin: auto;
  display: flex;
  text-align: left;

  .risk-item {
    flex: 0.1;
    margin-top: auto;
    text-align: center;
  }

  .risk-score-relative {
    position: relative;
    left: -15px;
  }

  .min-value,
  .max-value {
    -ms-flex-direction: column;
    -ms-flex-item-align: end;
    font-size: $font-size-small;
  }

  .risk-item:nth-child(2) {
    position: relative;
    flex-grow: 1;
  }

  .chart-container {
    max-height: 103px;
    overflow-y: hidden;
  }
}

.more-info {
  font-size: $font-size-small;
}

.rtl {
  .risk-container {
    flex-direction: row-reverse;
  }
}

.no-outline:focus {
  outline: none;
}

@import '@design';

.text-container {
  max-width: 700px;
}

.training-complete {
  max-width: 150px;
}

.complete-wrapper {
  min-height: 250px;
}

$orange: rgb(242, 103, 33);
$gray: #bdbdbf;

$gray-100: lighten($gray, 20%);
$gray-200: lighten($gray, 15%);
$gray-300: lighten($gray, 8%);
$gray-400: $gray;
$gray-500: darken($gray, 28%);
$gray-600: darken($gray, 32%);
$gray-700: darken($gray, 40%);
$gray-800: darken($gray, 50%);
$gray-900: darken($gray, 60%);

$color-white--semi-transparent: rgba(255, 255, 255, 0.6);
$color-white: #fff;
$color-black: #000;
$color-red: #a94442;
$color-black--transparent: rgba(0, 0, 0, 0.2);
$color-gray: #bdbdbf;
$color-lighter-gray: lighten($color-gray, 20%);
$color-light-gray: lighten($color-gray, 8%);
$color-darker-gray: darken($color-gray, 25%);
$color-dark-gray: darken($color-gray, 60%);
$color-border-gray: $color-lighter-gray;

// CONTENT
// $color-body-bg: #f9f7f5;
// $color-text: #444;
// $color-heading-text: #35495e;

// // LINKS
// $color-link-text: #39a275;
// $color-link-text-active: $color-text;

// // INPUTS
// $color-input-border: lighten($color-heading-text, 50%);

// // BUTTONS
// $color-button-bg: $color-link-text;
// $color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
// $color-button-text: white;

.progress-label {
  max-width: 400px;
}

.percentage-label {
  font-size: 2.2rem;
  font-weight: bold;
}

svg {
  font-size: 4rem;
  opacity: 0.8;
}

@import '@design';

.rtl {
  .dropdown-menu {
    text-align: right;
  }
}

.btn.btn-link {
  color: $gray-800;

  &:hover {
    color: $gray-900 !important;
  }
}

.dropDownBtnNoPadding {
  margin: 0 !important;
}

.dropDownBtnNoPadding > .btn {
  padding: 0;
  border: none !important;
}

.language-select-dropdown {
  .dropdown-menu {
    width: $language-select-max-width;
    .dropdown-item {
      color: $gray-800;
      &.active {
        color: $gray-900;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .dropDownBtnNoPadding {
    padding-left: 0 !important;

    .btn {
      text-align: left;
      padding-left: 0 !important;
    }
  }

  .language-select-dropdown {
    .btn {
      text-align: center;
    }

    svg {
      display: inline-block;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dropDownBtnNoPadding {
    padding-top: $padding-medium;

    svg {
      display: none;
    }
  }

  .language-select-dropdown {
    .dropdown-menu {
      max-width: $language-select-max-width-small;
      max-height: $language-select-max-height-small;
      overflow-y: auto;
    }

    .btn {
      text-align: center;
    }

    svg {
      display: inline-block;
    }
  }
}


















































































































.knowledge-refresher-container {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px 8px;
  margin-block: 7px;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
  &.completed {
    &:hover {
      cursor: disabled;
    }
  }
  &:first-child {
    margin-block-start: 35px;
  }
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    max-width: 80%;
    text-align: start;
    .kr-icon {
      height: 40px;
      margin-inline: 15px;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    .kr-arrow {
      height: 20px;
    }
  }
}

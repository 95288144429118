.introjs-tooltip {
  min-width: 300px;
  max-width: 300px;
  padding: $padding-large;

  .introjs-tooltiptext {
    font-size: 0.85rem;
    font-weight: 200;
    color: $gray-600;
    -webkit-font-smoothing: antialiased;
  }

  b {
    font-weight: $font-weight-bold;
  }

  h3 {
    height: 28px;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: $gray-800;
  }

  .introjs-bullets {
    text-align: left;
    ul li {
      margin: 0 6px 0 0;
      a {
        width: 6px;
        height: 6px;
        background: $gray-200;
      }
      a.active {
        background-color: $gray-500;
      }
    }
  }

  .introjs-button {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 0.85rem;
    background-image: none;
  }

  .introjs-skipbutton,
  .introjs-skipbutton:hover {
    float: left;
    padding: 0;
    margin: 12px 0 0;
    font-size: 0.85rem;
    font-weight: 200;
    background: none;
    border: 0;
    box-shadow: none;
  }

  .introjs-prevbutton {
    display: none;
  }

  .introjs-nextbutton,
  .introjs-donebutton,
  .introjs-donebutton:hover {
    float: right;
    padding: 8px 30px;
    margin: 0;
    font-weight: 200;
    color: #fff;
    text-shadow: none;
    border-radius: $border-radius-1;
    box-shadow: none;
  }

  // Style hacks for when tour is done
  .introjs-nextbutton.introjs-disabled {
    display: none;
  }
}

.introjs-helperLayer {
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
  mix-blend-mode: overlay !important;
}

@import '@design';

.confetti-container {
  position: relative;
  height: 350px;
  padding-top: 9em;
  overflow: hidden;
  box-shadow: $card-box-shadow;
}

.card-title,
.card-subtitle {
  position: relative;
  z-index: 2;
}

$flutteriness: 150;
$spinnyness: 10;

[class|='confetti'] {
  position: absolute;
}

@for $i from 0 through 150 {
  $w: random(15);
  $l: random(100);

  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    top: unquote(random(100) + '%');
    left: unquote(random(100) + '%');
    transform: rotate(#{$spinnyness * (75 - $i)}deg);
  }

  .confetti-animated-#{$i} {
    top: -20%;
    left: unquote($l + '%');
    animation: drop-animated-#{$i} unquote(10 + random(3) + 's') infinite;
    animation-delay: unquote($i * 100 + 'ms');
  }

  @keyframes drop-animated-#{$i} {
    100% {
      top: unquote(110 + $i + '%');
      left: unquote($l + random(15) + '%');
      transform: rotate3d(
          $i % 2,
          ($i / 2) % 2,
          0,
          #{$flutteriness * (75 - $i)}deg
        )
        rotate(#{$spinnyness * (75 - $i)}deg);
    }
  }
}
